const redirectionPatterns = [
    /^\/code-review\/public\/[0-9a-fA-F]+/,
    /^\/code-review\/[0-9]+/,
    /^\/challenge-invitation\/[0-9a-fA-F]+\/prerequisites/,
  ];

  function shouldRedirectToApp() {
    return redirectionPatterns.some(pattern => pattern.test(window.location.pathname));
  }

  function redirectionUrl() {
    return "{{ site[jekyll.environment].app_url }}" + window.location.pathname.replace(/\/$/, "")
  }

  if (shouldRedirectToApp()) {
    window.location.replace(redirectionUrl());
  }

  function printUrl(url) {
    url = url.replace(/\/$/, "");
    url = url.replace(/^https:\/\//, "");
    url = url.replace(/^http:\/\//, "");
    url = url.replace(/:[0-9]+/, "");
    url = url.replace(/\//g, "/<wbr>");
    return url
  }

  document.addEventListener("DOMContentLoaded", function () {
    document.querySelector("#redirection-domain").href = "{{ site[jekyll.environment].app_url }}";
    document.querySelector("#redirection-domain").innerHTML = printUrl("{{ site[jekyll.environment].app_url }}");
    document.querySelector("#find-page-url").href = redirectionUrl();
    document.querySelector("#find-page-url").textContent = printUrl(redirectionUrl());
  });